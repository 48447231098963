import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import customStorage from "./customStorage"; // fixed typo from "cutomStorage" to "customStorage"
import authSlice from "./slices/authSlice";
import { ingredientApi } from "../api/baseQueries/ingredientApi";
import { mealApi } from "../api/baseQueries/mealApi";
import { dietCategoryApi } from "../api/baseQueries/dietCategoryApi";

// REDUCERS SHOULD ONLY BE ADDED TO THIS CONFIG FOR PERSISTENCY
const rootReducer = combineReducers({
  auth: authSlice,
});

const persistedReducers = persistReducer(
  {
    key: "jadenourish",
    version: 1,
    storage: customStorage,
  },
  rootReducer
);

export const reducer = {
  [ingredientApi.reducerPath]: ingredientApi.reducer,
  [mealApi.reducerPath]: mealApi.reducer,
  [dietCategoryApi.reducerPath]: dietCategoryApi.reducer,
  persistedReducers
};
