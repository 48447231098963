import { configureStore } from "@reduxjs/toolkit";
import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from "react-redux";

/* Instruments */
import { reducer } from "./rootReducer";
// const { customLogger } = require("./middlewares/customLogger");
// const { rtkQueryErrorLogger } = require("./middlewares/rtkQueryErrorLogger");
import { setupListeners } from "@reduxjs/toolkit/query";
import { ingredientApi } from "../api/baseQueries/ingredientApi";
import { mealApi } from "../api/baseQueries/mealApi";
import { dietCategoryApi } from "../api/baseQueries/dietCategoryApi";

const middleWares = [
  // rtkQueryErrorLogger,
  // customLogger,
  ingredientApi.middleware,
  mealApi.middleware,
  dietCategoryApi.middleware,
];

export const reduxStore = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      middleWares
    );
  },
});

setupListeners(reduxStore.dispatch);

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useReduxDispatch();
export const useAppSelector = useReduxSelector;
